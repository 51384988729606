@import url(https://fonts.googleapis.com/css?family=Neuton:200,400);

html,
.App {
  padding: 0;
  margin: 0;
  font-family: 'Neuton', serif;
  font-size: 1.42em;
  font-weight: 200;
  line-height: 1.6em;
  background: #fff;
  color: #708B9E; 
}

#profile {
  margin: 80px auto;
  max-width: 510px;
  text-align: center;
  width: 70%;
}

#profile aside,
#profile section {
  font-size: .6em;
  line-height: 1.35em;
}

#profile aside a.openai {
  color: rgb(104, 117, 217);
  text-decoration: none;
  transition: 0.2s all ease-in;
  font-weight: 400;
}

#profile aside a.openai:hover {
  color: #000;
}

#profile header h1 {
  color: #444;
  font-size: 1.2em;
  font-weight: 400;
  left: 0px;
  margin: 25px auto;
  position: relative;
}

section {
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #eee;
  font-size: 0.5em;
  line-height: 1.5em;
  margin: 30px auto;
  padding: 30px;
  width: 80%;
}

section a {
  font-weight: 400;
  color: #636EDD;
  text-decoration: none;
  transition: .2s all ease-in;
}

section span.google {
  font-weight: 400;
  transition: .2s all ease-in;
}


section span.google:hover {
  color: #000;
}

section a:hover {
  color: #000;
}

.thumb {
  border-radius: 50%;
  max-width: 180px;
  width: 55%;
  height: 55%;
  animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; filter: alpha(opacity=0); }
  to   { opacity: 1; filter: alpha(opacity=100); }
}

@media (prefers-color-scheme: dark) {
  html, 
  .App {
    background: #202124;
    color: #909192;
  }
  #profile header h1 {
    color: #a7abaf;
  }
  #profile aside a.openai:hover {
    color: #d6e3f7;
  }
  section {
    border-color: #363a40;
  }
  section span.google:hover {
    color: #d6e3f7;
  }
  section a:hover {
    color: #d6e3f7;
  }
  .thumb {
    border: 1px solid #363a40;
    margin: -1px 0 0 -1px;
  }
} 
